<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>短信营销</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="130px" class="login-form">
            <div class="block">
                <div class="header">短信推送</div>
                <div class="list">
                    <el-form-item prop="title" label="营销活动标题：">
                        <el-input v-model="ruleForm.title" placeholder="请输入营销活动标题" style="max-width:300px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="tag4" label="营销目的：">
                        <div>
                            <el-checkbox-group v-model="ruleForm.tag4">
                                <el-checkbox v-for="(item,idx) in tagList4" :key="idx" :label="item.id">
                                    {{item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-form-item>
                    <el-form-item prop="tpl_id" label="模板选择：">
                        <div>
                            <el-select @change="selectTemp" v-model="ruleForm.tpl_id" placeholder="请选择模板">
                                <el-option v-for="item in tempList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                                <!-- <el-option :label="'无套系'" :value="''">无套系</el-option> -->
                            </el-select>
                        </div>
                    </el-form-item>
                    <div class="template">
                        <div class="template-title flex">
                            <div>模板内容：</div>
                        </div>
                        <div v-for="(item,idx) in ruleForm.tpl_param" :key="idx" class="template-item flex">
                            <el-form-item :label="item.title+'：'" :prop="'tpl_param.'+ idx +'.value'" :rules="rules.value">
                                <el-input placeholder="请输入内容" v-model="item.value"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="flex" style="padding-top:30px;">
                        <div><el-button @click="submitForm(1)" style="width: 80px;margin-right:20px;" type="primary">预发送</el-button></div>
                        <div><el-button @click="submitForm(2)" style="width: 80px;" type="primary">确定</el-button></div>
                    </div>
                </div>
            </div>
        </el-form>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <SendSms :form="form" @hide="popVisible=false"></SendSms>
        </el-dialog>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { listTag,listMsgTpl,sendMsg } from '@/service/crm';
    import SendSms from './SendSms.vue';
    export default {
        components: {
            SendSms
        },
        data() {
            return {
                popTitle:null,
                popVisible:false,
                rules: {
                    title:[{required: true, message: '请输入营销活动标题', trigger: 'blur'}],
                    tag4:[{required: true, message: '请选择标签', trigger: 'blur'}],
                    tpl_id:[{required: true, message: '请选择模板', trigger: 'blur'}],
                    value:[{ required: true, message: '请填写内容', trigger: 'blur' }],
                },
                ruleForm:{
                    comp_ids:[],
                    title:null,
                    tag4:[],
                    tpl_id:null,
                    tpl_param:[],
                },
                tagList4:[],
                tempList:[],
                form:null,
            }
        },
        async created() {
            this.ruleForm.comp_ids = this.$route.query.ids.split('.');
            this.tagList4 = await listTag({tag_type:4,stat:1,});
            this.tempList = await listMsgTpl();
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            back() {
                this.$router.go(-1)
            },
            selectTemp() {
                let tpl_param = this.tempList.find((e)=> {
                    return e.id == this.ruleForm.tpl_id;
                })
                this.ruleForm.tpl_param = tpl_param.params;
                console.log(tpl_param);
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        if (type==1) this.send();
                        if (type==2) this.submit();
                    }
                });
            },
            send() {
                this.popTitle = "预发送";
                this.popVisible = true;
                this.form = {
                    mobile:null,
                    title:this.ruleForm.title,
                    tpl_id:this.ruleForm.tpl_id,
                    tpl_param:this.ruleForm.tpl_param,
                }
            },
            submit() {
                console.log(this.ruleForm);
                sendMsg(this.ruleForm).then(rst => {
                    this.back();
                    this.$message.success("发送成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 20px;
    }
    .template-title>div:first-child {
        width:110px;
        padding:0 10px 20px 0;text-align:right;
    }
    .template-item {
        max-width: 300px;
    }
</style>